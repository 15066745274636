<button class="btn btn--more" tabindex="0" title="Sluit" modal-close-btn>
    <span>Sluit</span>
    <svg class="svg-icon svg-icon--xs">
        <use href="assets/icons-ui.svg#cross-closed"></use>
    </svg>
</button>
<h2 modal-title></h2>
<div modal-content>
    <div [class.chatbot-modal--fullscreen]="isFullScreen">
        <ins-chatbot-container
            [isMobile]="isFullScreen"
            [isDialog]="true"
            [question]="question"
            [maxHeight]="isFullScreen ? undefined : '65vh'"
            scrollTarget="list"
        ></ins-chatbot-container>
    </div>
</div>
