import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NewModalModule } from 'outshared-lib';
import { ChatbotModule } from '../chatbot/chatbot.module';
import { ChatbotModalComponent } from './chatbot-modal.component';

/** @deprecated - this is moved to libs */
@NgModule({
    declarations: [
        ChatbotModalComponent,
    ],
    imports: [
        CommonModule,
        NewModalModule,
        ChatbotModule,
    ],
})
export class ChatbotModalModule { }
