import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NewModalModule } from 'outshared-lib';
import { ChatbotModule } from '../chatbot/chatbot.module';
import { ChatbotFixedComponent } from './chatbot-fixed.component';
import { ChatbotFixedContainer } from './chatbot-fixed.container';

/** @deprecated - this is moved to libs */
@NgModule({
    imports: [
        CommonModule,
        ChatbotModule,
        NewModalModule,
    ],
    declarations: [
        ChatbotFixedContainer,
        ChatbotFixedComponent,
    ],
    exports: [
        ChatbotFixedContainer,
    ],
    providers: [],
})
export class ChatbotFixedModule { }
