import { Component, Inject, OnInit } from '@angular/core';
import {
    NEW_MODAL_DATA as MODAL_DATA,
    NewModalRef as ModalRef,
} from 'outshared-lib';

/** @deprecated - this is moved to libs */
@Component({
    selector: 'ins-chatbot-modal',
    templateUrl: './chatbot-modal.component.html',
    styleUrls: ['./chatbot-modal.component.scss'],
})
export class ChatbotModalComponent implements OnInit {

    public isFullScreen: boolean = false;

    // eslint-disable-next-line no-useless-constructor
    constructor(
        private modalRef: ModalRef<ChatbotModalComponent>,
        @Inject(MODAL_DATA) public question: string,
    ) {
    }

    public ngOnInit(): void {
        this.isFullScreen = this.modalRef._containerInstance._config.fullScreen;
    }

    public close(): void {
        this.modalRef.close();
    }
}
